import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In the first half of this season over 500 singletracks were added to
`}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{`. Trailguide gets better the
more bikers use it and share their trails. It is like a little investment of
everyone: share the few trails you know and get back thousands of other trails
wherever you go.`}</p>
    <br />
    <br />
    <p>{`We want to thank the contributors for their dedication and for beeing part of
the Trailguide community. We draw one user out of everyone who uploaded trails
and send him to a great bike weekend. This is only possible because of our
partners: the `}<a parentName="p" {...{
        "href": "https://www.aletscharena.ch"
      }}>{`Aletsch Arena`}</a>{` and the
`}<a parentName="p" {...{
        "href": "https://www.hotelalpenblick.ch"
      }}>{`Hotel Alpenblick`}</a></p>
    <h2>{`And the winner is...`}</h2>
    <h4>{`... Jonas Modin Rismyhr, Norway`}</h4>
    <Image src="news/userawards181_1.jpeg" mdxType="Image" />
    <p>{`The picture above shows him on a bike trip in Colorado, USA. Most of the
trails he added, are around Valldal, Norway:`}</p>
    <TrailguidePlugin content="lat=62.3145&lng=7.2896&coverage=10" mdxType="TrailguidePlugin" />
    <h4>{`We want to introduce Jonas with a little interview:`}</h4>
    <p><strong parentName="p">{`What is your local riding location?`}</strong></p>
    <p>{`Right now I am on the move, but I spent the last year riding in Valldal, Norway.`}</p>
    <br />
    <p><strong parentName="p">{`How many days did you spent on your bike last year?`}</strong></p>
    <p>{`According to Strava, around 70 days. In other words, not enough!`}</p>
    <br />
    <p><strong parentName="p">{`What do you use to track your rides (gps device, software, ...)?`}</strong></p>
    <p>{`Polar V800 watch.`}</p>
    <br />
    <p><strong parentName="p">{`What are your favourite bike destinations you traveled to?`}</strong></p>
    <p>{`That’s hard question, maybe Colorado and Caucasus.`}</p>
    <br />
    <p><strong parentName="p">{`Where do you want to ride in the future?`}</strong></p>
    <p>{`Thinking about doing more expedition trail biking in United States,
but I have so many future projects.`}</p>
    <br />
    <p><strong parentName="p">{`What makes a perfect day out biking for you?`}</strong></p>
    <p>{`To climb up a tough mountain with a good friend before descending down a
narrow singletrack with both flow and technical features.`}</p>
    <br />
    <p><strong parentName="p">{`How do you use Trailguide?`}</strong></p>
    <p>{`I check it for trail tips when I travel to new destination and like to
contribute so other people can have the same pleasure on places I have ridden.`}</p>
    <br />
    <p><strong parentName="p">{`What functions would you like to have in Trailguide?`}</strong></p>
    <p>{`I think it’s pretty darn good, but would love to add pictures to trails
that other people have uploaded.`}</p>
    <Image src="news/uploadtrails_2.jpg" mdxType="Image" />
    <p>{`Congratulations to Jonas. He and a friend win two nights in the
`}<a parentName="p" {...{
        "href": "https://www.hotelalpenblick.ch"
      }}>{`Hotel Alpenblick`}</a>{` including lift tickets for
two days in the unique `}<a parentName="p" {...{
        "href": "https://www.aletscharena.ch"
      }}>{`Aletsch Arena`}</a>{`. Enjoy
the stay in the stunning landscape with the longest glacier of the Alps and
some of the most amazing descents. Find out more about the trails of the
Aletsch Arena on `}<a parentName="p" {...{
        "href": "/destination/switzerland/aletsch"
      }}>{`Trailguide`}</a></p>
    <Image src="news/logouserawards3.jpg" mdxType="Image" />
    <p>{`Thanks again to everyone who contributes and for beeing part of the
Trailguide Community! We hope you enjoy Trailguide as much as we do and we
are looking forward to a great second half of the season with many exciting
new trails and destinations to discover!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      